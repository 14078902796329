<template>
    
    <div v-if="load==true">
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <div style="width: auto; margin: auto auto;">
            <br>
            <v-card>
                <v-card-title>
                    <span class="headline">Assign Roles to User</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row dense>
                            <v-col cols="12" sm="4">
                                <v-autocomplete v-model="seluser" :items="users" return-object item-text="username" label="Select User" prepend-icon="mdi-account" />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <br />
                                <v-chip outlined pill v-if="seluser.usertype">
                                    <v-icon size="20" left color="black">mdi-account</v-icon>
                                    User Type Of {{seluser.username}} :: {{seluser.usertype}}
                                </v-chip>
                            </v-col>

                            <v-col cols="12" md="6">
                                <div v-if="roles.length > 0">
                                    Roles <v-checkbox v-for="role in roles" :key="role.id" v-model="addrole" :label="role.name" :value="role.id" color="success"></v-checkbox>
                                    <span v-if="addrole.length ==0">
                                        <v-chip outlined pill>
                                            <v-icon left color="red">mdi-exclamation-thick</v-icon>
                                            Please select roles
                                        </v-chip>
                                    </span>
                                </div>
                                <v-btn :disabled="addrole.length < 1" color="indigo darken-4" dark class="mb-2" @click="addRoleToUser">Add Roles </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-col cols="12" sm="12">
            <br />
            <center><v-chip outlined pill v-if="seluser.usertype">
                <v-icon size="20" left color="black">mdi-account</v-icon>
                    Roles of User {{seluser.username}}
            </v-chip></center>
        </v-col>
                
        <v-simple-table v-if="curr_roles.length > 0">
            <template v-slot:default>
                <!-- <caption class="headline">
                    Roles of User {{seluser.username}}
                </caption> -->
                <thead>
                    <tr>
                        <td colspan="3" align="right">
                            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details style="width: 40%;"></v-text-field>
                        </td>
                    </tr>
                    <br />
                    <tr>
                        <th class="text-left">Role</th>
                        <th class="text-left">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in tablerolefilterdata" :key="item.id">
                        <td>{{ item.role }}</td>
                        <td>
                            <div>
                                <v-avatar style="" size="25" class="delete-avatar">
                                    <v-icon class="mr-2 delete-v-icon" color="red"  @click="deleteRoleToUser(item.userid,item.roleid)" title="Delete role">delete</v-icon>
                                </v-avatar>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
                </v-card-text>
            </v-card>
        </div>
        
    </div>
    
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return{
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search:'',
            load:false,
            seluser:{
                username:'',
                usertype:''
            },
            addrole:[],
            seluser_usertype:'',
            selmodule:'',
            users:{},
            modules:[],
            roles:{},
            curr_roles:{}
        }
    },
    mounted() {
        this.onload()
  },
  watch:{
      seluser(){
          this.getroles() 
          //this.seluser_usertype = this.users[this.seluser]
      },
      selmodule(){
          this.getroles()
      }, 
  },
  methods:{
      onload(){
        axios
      .post("/AdminUser/rolestouser")
      .then(res => {
        if (res.data.msg == "200") {
            this.users = res.data.users
            // this.modules = res.data.modules
            this.load = true
        } 
        else {
        }
      })
      .catch(error => {
        window.console.log(error);
      });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
      deleteRoleToUser(userid,roleid){
        //alert(userid+" "+roleid)
        const req_data={
                  userid:userid,
                  roleid:roleid
              }
             axios
            .post("/AdminUser/removerole",req_data)
            .then(res => {
                if (res.data.msg == "200") {
                    this.addrole=[];
                    this.getroles()
                    this.showSnackbar(
                  "#4caf50",
                  "User Role Deleted Successfully..."
                ); // show snackbar on success
                } 
                else {
                }
            })
            .catch(error => {
                window.console.log(error);
            }); 
      },
     
      addRoleToUser(){
          //alert("addRoleToUser"+this.addrole+" "+this.seluser)
            const req_data={
                  username:this.seluser.username,
                  roleid:this.addrole
              }
             axios
            .post("/AdminUser/assignrole",req_data)
            .then(res => {
                if (res.data.msg == "200") {
                    this.addrole=[];
                    this.getroles()
                    this.showSnackbar(
                  "#4caf50",
                  "New Role Added Successfully..."
                ); // show snackbar on success
                } 
                else {
                }
            })
            .catch(error => {
                window.console.log(error);
            }); 
      },
      getroles(){
          if((this.seluser.username!='')){
              const req_data={
                  username:this.seluser.username,
                //   module:this.selmodule
              }
                axios
            .post("/AdminUser/getuserroles",req_data)
            .then(res => {
                if (res.data.msg == "200") {
                    this.roles = res.data.roles
                    this.curr_roles = res.data.curr_roles
                } 
                else {
                }
            })
            .catch(error => {
                window.console.log(error);
            }); 
                }
        }
    },
    computed:{
        tablerolefilterdata(){
        
        return this.curr_roles.filter(tbdata=>{
           return tbdata.role.toLowerCase().indexOf(this.search.toLowerCase())>=0 ||
                  tbdata.module.toLowerCase().indexOf(this.search.toLowerCase())>=0 
                  
        })
      }
    }
}
</script>
<style scoped>
.delete-avatar{
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.delete-avatar:hover{
  background-color: #b0bec5;
}
.delete-v-icon{
  padding-left: 8px;
  padding-top: 1px;
  font-size: 15px;
}
.delete-v-icon:hover{
  color: white;
}
</style>